































import Vue from 'vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';

export default Vue.extend({
  name: 'Report-Info-Component',
  components: { CloseBtn },
});
