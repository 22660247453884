
































































import Vue from 'vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import ReportInfo from '@/blueprint/components/landlord/reports/abstraction/Report-Info.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';

export default Vue.extend({
  name: 'Report-Card-Component',
  components: {
    Loading,
    'report-info': ReportInfo,
  },
  props: {
    building: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      default: 'Report Title',
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data: () => {
    return {
      showActions: true,
      showInfo: false,
    };
  },
});
