

































import Vue from 'vue';
import BaseReport from '@/blueprint/components/landlord/reports/abstraction/Report-Card.vue';
import LineChart from '@/blueprint/components/ui/charts/Line.vue';
import { ChartData, ChartPoint } from '@/blueprint/components/ui/charts/interfaces';

import { GetDayOfWeek } from '@/assets/mixins';

import { RootStore } from '@/store';
import { LandlordState } from '@/store/landlord/state';
import { PropertyReport, DateBreakdown } from '@lordly/models2/interfaces/models/Analytics';

// Report: Portfolio Performance -> Searches -> Evolution -> More Info -> Enquiries
export default Vue.extend({
  name: 'L-Report1-Component',
  components: {
    'base-report': BaseReport,
    'line-chart': LineChart,
  },
  data: () => {
    return {
      datacollection: {} as ChartData,
    };
  },
  computed: {
    rootState (): RootStore {
      return this.$store.state as RootStore;
    },
    landlordState (): LandlordState {
      return this.rootState.landlord as LandlordState;
    },
    loading: {
      get (): boolean {
        return this.landlordState.reporting.loading;
      },
    },
    lastRefresh: {
      get (): string {
        return this.landlordState.reporting.lastRefresh;
      },
    },
  },
  watch: {
    lastRefresh () {
      this.populateReportData();
    },
  },
  // Lifecycle Hooks
  mounted () {
    this.populateReportData();
  },
  // Methods
  methods: {
    populateReportData () {
      // Collect all views across property
      let portfolioViews: Record<string, number> = {};
      let portfolioEvolutions: Record<string, number> = {};
      let portfolioMoreInfos: Record<string, number> = {};
      let portfolioEnquiries: Record<string, number> = {};

      // Tansform group1 data set
      const dataset: LandlordState['reporting']['group1'] = this.landlordState.reporting.group1;
      for (let idx in dataset.portfolio) {
        if (dataset.portfolio[idx]) {
          const data: PropertyReport = dataset.portfolio[idx];
          portfolioViews = this.sumData(data, 'views', portfolioViews);
          portfolioEvolutions = this.sumData(data, 'evolutions', portfolioEvolutions);
          portfolioMoreInfos = this.sumData(data, 'moreinfos', portfolioMoreInfos);
          portfolioEnquiries = this.sumData(data, 'enquiries', portfolioEnquiries);
        }
      }

      // Initialise new chart data
      const viewsChartPoints: ChartPoint[] = this.convertToChartPoint(portfolioViews);
      const newChartData: ChartData = {
        labels: [],
        datasets: [
          {
            label: 'Appeared in Searches',
            data: viewsChartPoints,
            fill: false,
            backgroundColor: '#464646',
            borderColor: '#464646',
          },
          {
            label: 'Slight Interest',
            data: this.convertToChartPoint(portfolioEvolutions),
            fill: false,
            backgroundColor: '#794F55',
            borderColor: '#794F55',
          },
          {
            label: 'Major Interest',
            data: this.convertToChartPoint(portfolioMoreInfos),
            fill: false,
            backgroundColor: '#AD5763',
            borderColor: '#AD5763',
          },
          {
            label: 'Enquiries',
            data: this.convertToChartPoint(portfolioEnquiries),
            fill: true,
            backgroundColor: '#E06072',
            borderColor: '#E06072',
          },
        ],
      };

      // Set new chart data
      this.datacollection = newChartData;

      // Populate labels - Get day of week (Based on views but should be same for evolutions and moreinfos as they are so interconnected)
      for (const idx in viewsChartPoints) {
        if (viewsChartPoints[idx]) {
          this.datacollection.labels!.push(GetDayOfWeek(viewsChartPoints[idx].x as string, true));
        }
      }
    },
    sumData (data: PropertyReport, attribute: keyof PropertyReport, lookup: Record<string, number>) {
      // Initialise empty date lookup
      if (data[attribute] instanceof Array) {
        for (let idx in data[attribute] as DateBreakdown[]) {
          if (data[attribute][idx]) {
            const dateObject: DateBreakdown = data[attribute][idx] as DateBreakdown;
            // Initialise date if it does not exist
            if (!lookup[dateObject.date]) {
              lookup[dateObject.date] = 0;
            }
            // Sum
            lookup[dateObject.date] += dateObject.count;
          }
        }
      }
      return lookup;
    },
    convertToChartPoint (data: Record<string, number>) {
      const keys: string[] = Object.keys(data);
      const chartPoints: ChartPoint[] = [];
      for (let idx in keys) {
        if (keys[idx]) {
          chartPoints.push({
            x: keys[idx],
            y: data[keys[idx]],
          });
        }
      }
      return chartPoints;
    },
  },
});
