
































import Vue from 'vue';
import BaseReport from '@/blueprint/components/landlord/reports/abstraction/Report-Card.vue';
import ReportTable from '@/blueprint/components/landlord/reports/abstraction/Report-Table.vue';
import L, { Layer, Path, LeafletMouseEvent, GeoJSON } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { GetCachedData } from '@/assets/mixins';

import { RootStore } from '@/store';
import { LandlordState } from '@/store/landlord/state';

import { UKRegions, ExternalRegion, GeoRegionBreakdown } from '@lordly/models2/interfaces/models/Analytics';

// Note: Use Vue -> Leaflet.js to create region map, use postcode to lookup region and then do a region count
// Report: Search region map -> Broken down by search -> evolution -> more info -> enquiries (https://github.com/sgratzl/chartjs-chart-geo)
export default Vue.extend({
  name: 'L-Report2-Component',
  components: {
    'base-report': BaseReport,
    'report-table': ReportTable,
  },
  data: () => {
    return {
      map: null as unknown as L.Map,
      tiles: null as unknown as L.TileLayer,
      geoJson: null as unknown as L.GeoJSON,
      info: null as unknown as L.Control,
      zoom: 5.25,
      center: [54.9, -3.5] as [number, number],
      bounds: null,
      // Table Data
      tableData: [] as GeoRegionBreakdown[],
    };
  },
  computed: {
    orderedTableData (): GeoRegionBreakdown[] {
      let oldList: GeoRegionBreakdown[] = this.tableData;
      // Order by high to low
      oldList.sort((a, b) => {
        if (a.count <= b.count) {
          return 1;
        } else {
          return -1;
        }
      });
      return oldList;
    },
    tableDataTotal (): number {
      let total: number = 0;
      this.tableData.forEach((data) => {
        total += data.count;
      });
      return total;
    },
    rootState (): RootStore {
      return this.$store.state as RootStore;
    },
    landlordState (): LandlordState {
      return this.rootState.landlord as LandlordState;
    },
    loading: {
      get (): boolean {
        return this.landlordState.reporting.loading;
      },
    },
    lastRefresh: {
      get (): string {
        return this.landlordState.reporting.lastRefresh;
      },
    },
  },
  watch: {
    lastRefresh () {
      this.populateReportData();
    },
  },
  // Lifecycle Hooks
  mounted () {
    // Determine viewport to set zoom as in mobile viewports you want it to be a little zoomed ut
    if (window.innerWidth < 599) {
      this.zoom = 4.5;
    }
    this.$nextTick(() => {
      this.populateReportData();
    });
  },
  // Methods
  methods: {
    populateReportData () {
      // Tansform group1 data set
      const dataset: LandlordState['reporting']['group1'] = this.landlordState.reporting.group1;
      // Copy array such that component can mutate data slightly
      this.tableData = [];
      dataset.geo.forEach((dataPoint) => {
        this.tableData.push(Object.assign({}, dataPoint));
      });
      this.renderMap();
    },
    renderMap () {
      // Force rerender
      if (this.map) {
        this.map.remove();
      }
      // Initialise Leaflet map
      this.map = L.map(this.$refs['map'] as HTMLElement, {
        zoomControl: false,
        zoom: this.zoom,
        zoomSnap: 0.25,
        center: this.center,
      });
      // Disable various actions to prevent user from messing with view
      this.map.dragging.disable();
      this.map.doubleClickZoom.disable();
      this.map.keyboard.disable();
      this.map.boxZoom.disable();
      this.map.scrollWheelZoom.disable();

      // Add regions to map via Choropleth map (Async load)
      GetCachedData('geojson/GB/GB_CUST.json').then((response) => {
        if (response.status === 200) {
          // Initialise geojson for choropleth maps
          this.geoJson = L.geoJSON(this.mapDataToGeoJSON(response.data) as any, {
            style: this.style,
            onEachFeature: this.onEachFeature,
          });
          // Load map
          this.geoJson.addTo(this.map);
        } else {
          console.error(new Error('Unhandled response code ' + response.status));
        }
      }).catch((e) => {
        console.error(e);
      });

      // Add legend
      let legend: L.Control = new L.Control({position: 'bottomright'});
      // Create legend HTML
      legend.onAdd = (map) => {
        let div: HTMLElement = L.DomUtil.create('div', 'leaflet-custom-legend legend');
        let grades: number[] = [0, 1, 5, 7.5, 10];
        let labels: any[] = [];

        // Loop through our density intervals and generate labels with a coloured sqaure for each interval
        for (let i: number = 0; i < grades.length; i++) {
          div.innerHTML +=
              '<i style="background-color:' + this.getColour(grades[i] + 1) + '"></i> ' +
              grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '%<br>' : '%+');
        }
        return div;
      };
      // Add legend to map
      legend.addTo(this.map);

      // Add Info
      let info: L.Control = new L.Control();
      info.onAdd = function (map) {
        let context: any = this;
        context._div = L.DomUtil.create('div', 'leaflet-custom-info');
        context.update();
        return context._div;
      };
      // Method to update
      (info as any).update = function (props: GeoJSONProperties) {
        let context: any = this;
        context._div.innerHTML = '<h4>Portfolio Interest Breakdown (%)</h4>' +  (props ?
          `<p><span class="leaflet-custom-value">${props.value.toFixed(2)}%</span> of interest comes from<br/><b>${props.name}</br></p>`
          : '<b class="leaflet-custom-highlight">Hover over an area</b>');
      };
      info.addTo(this.map);
      // Initialise
      this.info = info;
    },
    getColour (value: number) {
      // Reminder: If updating margins, also update the mounted lifecycle - legend creation
      return value >  10 ? '#b30000' :
              value > 7.5 ? '#e34a33' :
              value > 5 ? '#fc8d59' :
              value > 1 ? '#fdcc8a' :
                          '#fef0d9';
    },
    style (feature: any) {
      return {
        fillColor: this.getColour(feature.properties.value),
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7,
      };
    },
    mapDataToGeoJSON (data: CustomGeoJSON) {
      // For each feature map data
      let total: number = 0;
      if (data.features instanceof Array) {
        for (let idx in data.features) {
          if (data.features[idx]) {
            // Extract feature
            const properties: GeoJSONProperties = data.features[idx].properties;
            // Map data to value
            const matchedData: GeoRegionBreakdown | undefined = this.tableData.find((value) => {
              return value.region === properties.name;
            });
            // Initialise if not exists (LIKELY)
            if (properties.value === undefined) {
              properties['value'] = 0;
            }
            // Overwrite with real data
            if (matchedData) {
              // Calculate %
              let percentage: number = (matchedData.count / this.tableDataTotal) * 100;
              total += percentage;
              properties.value = percentage;
            }
            // Overwrite properties
            data.features[idx].properties = properties;
          }
        }
      }
      // Return updated geojson
      return data;
    },
    highlightFeature (e: LeafletMouseEvent) {
      // Get layer
      let layer: Path & CustomLayer = e.target;

      if (layer) {
        layer.setStyle({
          weight: 3,
          color: '#464646',
          dashArray: '',
          fillOpacity: 0.7,
        });
      }

      // If supported browser bring to front
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }

      (this.info as any).update(layer.feature.properties);
    },
    resetHighlight (e: LeafletMouseEvent) {
      this.geoJson.resetStyle(e.target);
      (this.info as any).update();
    },
    onEachFeature (feature: unknown, layer: Layer) {
      // Add event listeners to layer
      layer.on({
        mouseover: this.highlightFeature,
        mouseout: this.resetHighlight,
      });
    },
  },
});

interface CustomGeoJSON {
  type: string;
  features: CustomGeoJSONFeature[];
}

interface CustomGeoJSONFeature {
  type: 'Feature';
  geometry: {
    type: 'MultiPolygon';
    coordinates: any[];
  };
  properties: GeoJSONProperties;
}

interface CustomLayer {
  feature: {
    properties: GeoJSONProperties;
  };
}

interface GeoJSONProperties {
  name: UKRegions;
  value: number;
}
