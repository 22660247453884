





















import Vue from 'vue';
import { MutationPayload, ActionPayload } from '@/interfaces';
// Report Cards
import Report1 from '@/blueprint/components/landlord/reports/Report1.vue';
import Report2 from '@/blueprint/components/landlord/reports/Report2.vue';
import Report3 from '@/blueprint/components/landlord/reports/Report3.vue';

// Interfaces
import { RootStore } from '@/store';
import { LandlordState } from '@/store/landlord/state';

export default Vue.extend({
  name: 'Reports-Page',
  components: {
    Report1,
    Report2,
    Report3,
  },
  inject: [],
  mixins: [],
  props: {},
  data: () => {
    return {};
  },
  computed: {
     rootState (): RootStore {
      return this.$store.state as RootStore;
    },
    landlordState (): LandlordState {
      return this.rootState.landlord as LandlordState;
    },
  },
  watch: {},
  // Lifecycle Hooks
  // beforeCreate () {},
  // created () {},
  // beforeMount () {},
  async mounted () {
    // Dispatch get report data
    if (!this.landlordState.reporting.lastRefresh) {
      const actionPayload: ActionPayload = {
        component: this,
      };
      await this.$store.dispatch('landlord/actionGetAnalyticsGroup1', actionPayload);
    }
  },
  // beforeUpdate () {},
  // updated () {},
  // beforeDestroy () {},
  // destroyed () {},
  // Methods
  methods: {},
});
