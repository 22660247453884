




























import Vue from 'vue';
import BaseReport from '@/blueprint/components/landlord/reports/abstraction/Report-Card.vue';
import ReportTable from '@/blueprint/components/landlord/reports/abstraction/Report-Table.vue';
import HBarChart from '@/blueprint/components/ui/charts/Horiziontal-Bar.vue';
import { ChartData, ChartPoint } from '@/blueprint/components/ui/charts/interfaces';

import { RootStore } from '@/store';
import { LandlordState, IndexedProperty } from '@/store/landlord/state';
import { PropertyReport, DateBreakdown } from '@lordly/models2/interfaces/models/Analytics';

// Report: Top performing properties, order from best to worse and can be reordered
export default Vue.extend({
  name: 'L-Report3-Component',
  components: {
    'base-report': BaseReport,
    'hbar-chart': HBarChart,
    'report-table': ReportTable,
  },
  data: () => {
    return {
      dataCollection: {} as ChartData,
      tableData: [] as PropertyPerformanceData[],
    };
  },
  computed: {
    rootState (): RootStore {
      return this.$store.state as RootStore;
    },
    landlordState (): LandlordState {
      return this.rootState.landlord as LandlordState;
    },
    loading: {
      get (): boolean {
        return this.landlordState.reporting.loading;
      },
    },
    lastRefresh: {
      get (): string {
        return this.landlordState.reporting.lastRefresh;
      },
    },
    orderedTableData (): TableData[] {
      let oldList: PropertyPerformanceData[] = this.tableData;
      // Convert to table data
      const newList: TableData[] = [];
      oldList.forEach((d) => {
        const summed: number = d.views + d.evolutions + d.moreinfos + d.enquiries;
        newList.push({
          property: d.property,
          value: summed,
        });
      });
      // Order by high to low
      newList.sort((a, b) => {
        if (a.value <= b.value) {
          return 1;
        } else {
          return -1;
        }
      });
      return newList;
    },
  },
  watch: {
    lastRefresh () {
      this.populateReportData();
    },
  },
  // Lifecycle Hooks
  mounted () {
    this.populateReportData();
  },
  methods: {
    sumData (data: DateBreakdown[]) {
      let count: number = 0;
      for (let idx in data) {
        if (data[idx]) {
          const date: DateBreakdown = data[idx];
          count += date.count;
        }
      }
      return count;
    },
    populateReportData () {

      // Transform group1 data set
      const dataset: LandlordState['reporting']['group1'] = this.landlordState.reporting.group1;

      // Get portfolios for lookup
      const propertyDictionary: Record<string, IndexedProperty> = {};
      const portfolio: IndexedProperty[] = this.landlordState.portfolio.properties;
      portfolio.forEach((value) => {
        propertyDictionary[value.property.id!] = value;
      });

      // Convert array to individual property performance data object
      this.tableData = [];
      for (const idx in dataset.portfolio) {
        if (dataset.portfolio[idx] && parseInt(idx) < 15) {
          const data: PropertyReport = dataset.portfolio[idx];
          const newProperty: PropertyPerformanceData = {
            // TODO: Lookup property to get first line and convert partition into city
            property:  data.id + ' • ' + data.partition,
            views: this.sumData(data.views),
            evolutions: this.sumData(data.evolutions),
            moreinfos: this.sumData(data.moreinfos),
            enquiries: this.sumData(data.enquiries),
          };
          // Override with address if exists
          if (propertyDictionary[data.id]) {
            let address: LandlordState['portfolio']['properties'][0]['property']['address'] = propertyDictionary[data.id].property.address!;
            newProperty.property = address.line1 + ' • ' + address.city;
          }
          // Handle general actions
          if (data.id === 'GA') {
            newProperty.property = 'General Enquiry';
          }
          this.tableData.push(newProperty);
        }
      }

      this.updateChartData();
    },
    updateChartData () {
      // Create initial empty object
      const chartData: ChartData = {
        labels: [],
        datasets: [
          {
            label: 'Appeared in Searches',
            backgroundColor: '#464646',
            data: [],
          },
          {
            label: 'Slight Interest',
            backgroundColor: '#794F55',
            data: [],
          },
          {
            label: 'Major Interest',
            backgroundColor: '#AD5763',
            data: [],
          },
          {
            label: 'Enquiries',
            backgroundColor: '#E06072',
            data: [],
          },
        ],
      };
      // populate chart object
      for (let idx in this.tableData) {
        if (this.tableData[idx]) {
          // Extract row
          const perfData: PropertyPerformanceData = this.tableData[idx];
          // Add labels
          chartData.labels!.push(perfData.property);
          // Add data views
          chartData.datasets![0].data!.push(perfData.views);
          // Add data evolutions
          chartData.datasets![1].data!.push(perfData.evolutions);
          // Add data moreinfos
          chartData.datasets![2].data!.push(perfData.moreinfos);
          // Add data enquiries
          chartData.datasets![3].data!.push(perfData.enquiries);
        }
      }
      // Update chart
      this.dataCollection = chartData;
    },
  },
});

interface PropertyPerformanceData {
  property: string;
  views: number;
  evolutions: number;
  moreinfos: number;
  enquiries: number;
}

interface TableData {
  property: string;
  value: number;
}
