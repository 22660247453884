




























import Vue from 'vue';

export default Vue.extend({
  name: 'Report-Table-Component',
  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [] as string[],
    },
    data: {
      type: Array,
      required: true,
      default: () => [] as object[],
    },
    columnClasses: {
      type: Array,
      required: false,
      default: () => [] as string[],
    },
  },
  data: () => {
    return {};
  },
  computed: {
    dataKeys (): string[] {
      let keys: string[] = [];
      if (this.data && this.data.length > 0) {
        const firstData: any = this.data[0];
        if (typeof firstData === 'object') {
          keys = Object.keys(firstData);
        }
      }
      return keys;
    },
  },
});
