



import Vue from 'vue';
import { BaseChart } from 'vue-chartjs/types/components';
import { ChartData, ChartOptions } from '@/blueprint/components/ui/charts/interfaces';
import { HorizontalBar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default Vue.extend({
  name: 'UI-Horiziontal-Bar-Chart-Component',
  extends: HorizontalBar,
  mixins: [reactiveProp],
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (value: number) => ((value % 1 === 0)  ? value : null),
            },
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
          }],
        },
      } as ChartOptions,
    };
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    (this as any).renderChart((this as any).chartData as ChartData, this.options);
  },
});
