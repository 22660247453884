



import Vue from 'vue';
import { BaseChart } from 'vue-chartjs/types/components';
import { ChartData, ChartOptions } from '@/blueprint/components/ui/charts/interfaces';
import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default Vue.extend({
  name: 'UI-Line-Chart-Component',
  extends: Line,
  mixins: [reactiveProp],
  data () {
    return {
      options: {
        bezierCurve: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (value: number) => ((value % 1 === 0)  ? value : null),
            },
          }],
        },
      } as ChartOptions,
    };
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    (this as any).renderChart((this as any).chartData as ChartData, this.options);
  },
});
